function About() {
  return (
    <div className="body-text">
      <p>
        Half a Million Mothers is a random mother generator for those whose
        assigned mother is faulty or unreliable. It is for anyone who
        experienced motherhood as a responsibility shared by many people; who
        were mothered by blood relatives, friends, teachers, mentors, bosses,
        pets, spirits and trees, in glimpses and fragments. It is either one
        extremely long poem, or 614,656 poems, depending on your perspective.
      </p>
      <p>
        Each new mother is configured from 112 micro prose poems I wrote about
        specific people who have mothered me in some way. In my experience,
        mothering encompasses a wide range of activities—not just raising and
        nurturing, but also exasperating, inspiring, suffocating, counselling,
        patronising, confusing, teaching, carrying, heart-breaking and loving.
        Each micro poem was designed to be recombined with three other
        randomised micro poems, to make a whole. The result is that each mother
        generated is 1 out of 614,656 possible combinations. With so many mothers
        to choose from, I hope you find as many as you need.
      </p>
      <p>
        Half a Million Mothers was written by Dylan McNulty-Holmes. The site was 
        designed and built by Dylan McNulty-Holmes and Matthew Liam Healy. It is 
        dedicated to my many mothers, especially those I have lost.
      </p>
    </div>
  )
}

export default About;