import { useState } from 'react';
import mothers from './data/mothers.json';

type AllMothers = string[][];

function generatePoem(mothers: AllMothers): string[] {
  const choices = Array.from(
    { length: 4 },
    () => Math.floor(Math.random() * mothers.length)
  );
  return choices.map((mother, line) => mothers[mother][line])
}

function Generator() {
  const DEFAULT_TEXT = "A random mother generator for those whose assigned mother is faulty or unreliable.";
  const [poem, setPoem] = useState<string[]>([DEFAULT_TEXT])

  return (
    <div>
      <Poem text={poem}/>
      <button className="generate-button" onClick={() => setPoem(generatePoem(mothers))}>
        Generate
      </button>
    </div>
  )
}

type PoemProps = {
  text: string[]
}

function Poem(props: PoemProps) {
  const lines = props.text.map((l, i) => <p key={i}>{l}</p>);
  return <div className="body-text">{lines}</div>
}

export default Generator;